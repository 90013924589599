import React from 'react'

import { BlogPage } from '../../shared/page-commons'
import MDBody, { _frontmatter } from './_ariadna_about.md'

export default function AriadnaAboutPage(props) {
  return (
    <BlogPage frontmatter={_frontmatter}>
      <MDBody />
    </BlogPage>
  )
}
