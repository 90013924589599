import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "About ARIADNA",
  "layout": "collabpage",
  "banner": "/images/core/ari_banner.jpg",
  "banner_caption": "ariadna",
  "regenerate": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Ariadna is mechanism for collaborative joint research projects between ESA's internal research think tank, the Advanced Concepts Team and academia. It was established in 2004 as an initiative to establish closer link with the European academic community in advanced space technology research.`}</p>
    <p>{`Ariadna projects are typically exploratory, high risk, time and resource limited research studies outside of the programmatic horizon of ESA. Research fields are typically not yet covered by ESA activities, and are not or only poorly explored in a space context.`}</p>
    <p>{`In the conduct of Ariadna projects, researchers from the ACT will form with researchers from universities an ad-hoc virtual team performing research together. The target communities are university research groups and departments (including their non-profit entities providing contractual and administrative support).`}</p>
    <h2>{`Submission of Proposal`}</h2>
    <p>{`One of the first step is to register your research entity in the ESA system (`}<a parentName="p" {...{
        "href": "https://esastar-emr.sso.esa.int/"
      }}>{`esastar`}</a>{`) and obtain what is called a "Bidder Code".`}</p>
    <p>{`The Member States of ESA have made it mandatory for ESA to register what are called "economic
operators" wishing to do business with ESA. You are thus requested to complete the online
questionnaire on the `}<a parentName="p" {...{
        "href": "https://esastar-emr.sso.esa.int/"
      }}>{`ESA-STAR registration website`}</a>{`.`}</p>
    <p>{`At the time when submitting the proposal, you should at least have already completed
the “Light Registration” as described in the following `}<a parentName="p" {...{
        "href": "http://esamultimedia.esa.int/docs/business_with_esa/CG-EM-ORR-S-16_End_User_Manual_v1.2.pdf"
      }}>{`ESA-STAR Registration User Manual`}</a></p>
    <h2>{`Type of Ariadna studies`}</h2>
    <p>{`In order to ease planning, improve transparency and enhance predictability of the associated administrative procedures, three types of studies have been defined`}</p>
    <ul>
      <li parentName="ul">{`Small Ariadna Studies: study budget not exceeding 20 k€.`}</li>
      <li parentName="ul">{`Standard Ariadna Studies: study budget not exceeding 30 k€.`}</li>
      <li parentName="ul">{`Extended Ariadna Studies: study budget not exceeding 40 k€.`}</li>
    </ul>
    <h2>{`Templates`}</h2>
    <h3>{`For Call for Proposals:`}</h3>
    <p>{`If you intend to prepare and submit a proposal in response to a Call for Proposals it is preferable that you use the template provided below: `}<a parentName="p" {...{
        "href": "/gsp/ACT/doc/ARI/ARI%20Call%20doc/ARIADNA%20-%20Ariadna%20Proposal%20Submission%20Template.doc"
      }}>{`Ariadna Proposal Submission Template`}</a></p>
    <p>{`The following documents - part of the Ariadna Initiative and related EXPRO (EXpress PROcurement) standard package - are made available for information only.`}</p>
    <p>{`For the preparation of your proposals in response to a given Call or RFP and for making the declarations of compliance required thereunder, you are kindly invited to exclusively refer to the actual solicitation package as tailored for the specific purpose and to use the templates attached thereto.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/gsp/ACT/doc/ARI/ARI%20Call%20doc/ARIADNA%20-%20Draft%20Contract.pdf"
        }}>{`Draft Contract`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/gsp/ACT/doc/ARI/ARI%20Call%20doc/ARIADNA%20-%20Tendering%20Conditions.pdf"
        }}>{`Tendering Conditions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/gsp/ACT/doc/ARI/ARI%20Call%20doc/ARIADNA%20-%20Ariadna%20Proposal%20Submission%20Template.doc"
        }}>{`Proposal Submission Template`}</a></li>
    </ul>
    <hr></hr>
    <h3>{`For Call for Ideas:`}</h3>
    <p>{`The template might present some slight variations with respect to the above generic template for the Call for Proposals, as it could be tailored accordingly to the theme proposed in the Call for Ideas. Call for Ideas will be channelled through the Open Space Innovation Platform (OSIP).`}</p>
    <h3>{`Templates for the study deliverables:`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/gsp/ACT/doc/ARI/ARI%20Call%20doc/Contract%20Closure%20Document.doc"
        }}>{`Contract Closure Document`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/gsp/ACT/doc/ARI/ARI%20Call%20doc/Ariadna_Final_Report_Template.doc"
        }}>{`Ariadna Final Report Template`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/gsp/ACT/doc/ARI/ARI%20Call%20doc/Ariadna_Executive_Summary_Template.doc"
        }}>{`Ariadna Executive Summary Template`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/gsp/ACT/doc/ARI/ARI%20Call%20doc/Ariadna_Questionnaire.doc"
        }}>{`Ariadna Questionnaire`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      